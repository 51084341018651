<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div style="">
        <div class="text-center">
            <v-dialog
                    v-model="dialog"
                    persistent
                    width="50"
            >
                <img src='../../assets/loader_spinner.gif' style="width: 50px; ">

            </v-dialog>
        </div>
        <v-row>
            <v-col>
                <v-container class="my-5 adminContainer">
                    <v-row>
                        <v-col cols="0" md="2">
                            <AdminStatMenu></AdminStatMenu>
                        </v-col>
                        <v-col cols="12" md="10">
                            <v-row>
                                <!--<v-col>
                                    <table class="table-left-s table-left-s-b">
                                        <colgroup>
                                            <col style="width:15%"/>
                                            <col style="width:85%"/>
                                        </colgroup>
                                        <tr>
                                            <th class="th">검색방법</th>
                                            <td class="td">
                                                <input type="radio" id="M" value="M" v-model="isSearch"
                                                       style="width: 20px; height: 12px;" @change="changeSearch($event)">
                                                <label for="M">월별(아래 년도를 선택하세요)</label>
                                                <input type="radio" id="Y" value="Y" v-model="isSearch"
                                                       style="width: 20px; height: 12px;" @change="changeSearch($event)">
                                                <label for="Y">년도별(전체)</label>
                                            </td>
                                        </tr>
                                        <template v-if="isSearch == 'M'">
                                            <tr>
                                                <th>
                                                    검색기간(월별)
                                                </th>
                                                <td>
                                                    <select v-model="resvYear" @change="changeYear($event)"
                                                            style="background-color: #fff;">
                                                        <option v-for="(n, i) in yearList" :value="n">{{n}}년</option>
                                                    </select>
                                                </td>
                                            </tr>
                                        </template>
                                    </table>
                                </v-col>-->
                                <v-col>
                                    <table class="table-left-s table-left-s-b">
                                        <colgroup>
                                            <col style="width:30%"/>
                                            <col style="width:70%"/>
                                        </colgroup>
                                        <tr>
                                            <th class="th">검색방법</th>
                                            <td class="td" style="overflow-wrap: break-word">
                                                <template v-if="!isMobile">
                                                    <input type="radio" id="M" value="M" v-model="isSearch"
                                                           style="width: 20px; height: 12px;"
                                                           @change="changeSearch($event)">
                                                    <label for="M">월별</label>
                                                    <input type="radio" id="Y" value="Y" v-model="isSearch"
                                                           style="width: 20px; height: 12px;"
                                                           @change="changeSearch($event)">
                                                    <label for="Y">년도별</label>
                                                </template>
                                                <template v-else>
                                                    <div>
                                                        <input type="radio" id="M" value="M" v-model="isSearch"
                                                               style="width: 20px; height: 12px;"
                                                               @change="changeSearch($event)">
                                                        <label for="M">월별</label>
                                                    </div>
                                                    <div>
                                                        <input type="radio" id="Y" value="Y" v-model="isSearch"
                                                               style="width: 20px; height: 12px;"
                                                               @change="changeSearch($event)">
                                                        <label for="Y">년도별</label>
                                                    </div>
                                                </template>
                                            </td>
                                        </tr>
                                        <template v-if="isSearch == 'M'">
                                            <tr>
                                                <th>
                                                    검색기간
                                                </th>
                                                <td>
                                                    <select v-model="resvYear" @change="changeYear($event)"
                                                            style="background-color: #fff;">
                                                        <option v-for="(n, i) in yearList" :value="n">{{n}}년</option>
                                                    </select>
                                                </td>
                                            </tr>
                                        </template>
                                    </table>
                                </v-col>
                                <v-col cols="12" class="sub-title-3 mt-2">
                                    월별 매출통계
                                </v-col>
                               <!-- <v-col cols="12">
                                    <select v-model="resvYear"  @change="changeYear($event)" class="select-d">
                                        <option v-for="(n, i) in yearList" :value="n">{{n}}년</option>
                                    </select>
                                </v-col>-->
                                <!--<v-col class="hidden-md-and-down">-->
                                <v-col cols="12">
                                    <span style="color: blue; font-weight: bold;">매출합계 : {{chartInfo.sum | comma}} 원</span>
                                </v-col>
                                <div class="overflow-x-auto">

                                    <div style="width: 900px; height: 450px;">
                                        <!--차트가 그려질 부분-->
                                        <canvas id="myChart"></canvas>
                                    </div>
                                </div>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    ※입금완료된 내역만 표시됩니다.<br>
                                    ※매출현황, 결재방법, 매출그래프는 신청(등록)일 기준으로 산출됩니다.
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                </v-container>
            </v-col>
        </v-row>

    </div>
</template>

<script>
    import AdminStatMenu from '../../components/AdminStatMenu'
    export default {
        name: 'AdminStatChart',
        components: {AdminStatMenu},
        data: () => ({
            resvYear: "",
            yearList: [],
            statList: [],
            chartInfo: {
                label: [],
                data: [],
                sum : 0
            },
            myChart: null,
            roomGubun:"",
            isSearch: "M",
            dialog: false,
            isMobile: false,

        }),
        created: function () {
            // 모바일 체크
            this.isMobile = this.$isMobile();
            // 맨위로
            window.scrollTo(0, 0);
            //this.$isAdminCheck(this.$store.state.admin.isLogin);
            //this.$isAuthCheck(this.$getCookie("AUTH"));

            let now = new Date();	// 현재 날짜 및 시간
            let year = now.getFullYear();	// 연도
            this.resvYear = String(year);

            for (year; year > 2020; year--) {
                this.yearList.push(year);
            }

            this.getList();

        },
        methods: {
            getList() {

                this.dialog = true;

                if (this.resvYear.length !== 4) {
                    alert("잘못된 요청입니다.");
                    this.dialog = false;
                    return;
                }

                let formdata = {};

                switch (this.isSearch) {
                    case "Y":{
                        formdata['isSearch'] = this.isSearch;
                        break;
                    }
                    case "M":{
                        formdata['isSearch'] = this.isSearch;
                        formdata['resvYear'] = this.resvYear;
                        break;
                    }

                }

                return this.$store.dispatch("admin/getStatChart", formdata)
                    .then((resp) => {
                        setTimeout(() => {

                            this.chartInfo.data = resp.data;
                            this.chartInfo.label = resp.label;
                            this.chartInfo.sum = 0;
                            let _this = this;
                            this.chartInfo.data.forEach(function (e) {
                                _this.chartInfo.sum += e;
                            })

                            this.setChart();

                        }, 300)
                    })
                    .catch((err) => {

                        alert(err);
                        this.dialog = false;

                    })


            },

            searchClick() {

                this.myChart.destroy();
                this.getList();

            },
            setChart() {

                if(this.myChart != null){
                    this.myChart.destroy();
                }

                let _this = this;

                var context = document
                    .getElementById('myChart')
                    .getContext('2d');

                this.myChart = new Chart(context, {
                    type: 'bar', // 차트의 형태
                    data: { // 차트에 들어갈 데이터
                        labels: _this.chartInfo.label,
                        datasets: [
                            { //데이터
                                label: '매출', //차트 제목
                                //fill: false, // line 형태일 때, 선 안쪽을 채우는지 안채우는지

                                data: _this.chartInfo.data,
                                /*[
                                    21, 19, 25, 20, 23, 26, 25 //x축 label에 대응되는 데이터 값
                                ],*/
                                backgroundColor: [
                                    //색상
                                    'rgba(54, 162, 235, 0.8)',

                                ],
                                borderColor: [
                                    //경계선 색상
                                    'rgba(54, 162, 235, 1)',

                                ],
                                borderWidth: 1 //경계선 굵기
                            }
                        ]
                    },
                    options: {
                        plugins: {
                            legend: {
                                display: false
                            }
                        },

                    }
                });

                this.dialog = false;


            },
            changeYear(event) {

                this.myChart.destroy();

                this.resvYear = event.target.value;
                this.getList();

            },
            changeSearch(event){
                this.getList();
            },

        },
        mounted() {
            this.$nextTick(() => {

            })
        },
        computed: {
            //resvConfirmInfo() { return this.$store.state.reservation.resvConfirmInfo }
        },
    }
</script>

<style>

</style>

